import { useState, useEffect, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate } from 'react-router-dom';

export default function Home({siteId, setSiteId}) {

  const navigate = useNavigate();
  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const [result, setResult] = useState(false);
  
  const [siteIdError, setSiteIdError] = useState(false);
  const [siteIdText, setSiteIdText] = useState('');

  const [passwordError, setPasswordError] = useState(false);
  const [passwordText, setPasswordText] = useState('');

  const[password,setPassword]=useState('');

  useLayoutEffect(() => {
    checkAction();
  }, [result]);


  const handleSiteIDChange = (newValue) => {
      
      setSiteId(newValue);
  };
  const handlePasswordChange = (newValue) => {
      
    setPassword(newValue);
};

const nextPage = async e  =>{
  setResult(false);

    if(siteId !== "" && siteId!== 0 && siteId !== null && password !== "" && siteId !== null){

      //check id, password exist on DB
      try {
        await axiosFetch({
          axiosInstance: axios,
          method: 'post',
          url: '/checkSiteStatus',
          requestConfig: {
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              "id":siteId,
              "password":password

          }
          }
  
        })
      } catch (err) {
        console.log(err);
  
      }
  
    setResult(true);

    }
    else{
      //display error message
      if(siteId=== null || siteId === "" || siteId === 0){
          setSiteIdText('Please provide your Dandara Site ID');
          setSiteIdError(true);
      }
      if(password=== null || password === "" ){
        setPasswordText('Please provide your Dandara Site Password');
        setPasswordError(true);
    }

    }



  }

  function checkAction(){
    if(response.status === 200){
      let res = response.userSiteStatus;

      if(res === false){
        setSiteIdText('The id or password is incorrect. Please try again.');
        setSiteIdError(true);
        setPasswordText('');
        setPasswordError(false);
      }
      else{
        setSiteIdText('');
        setSiteIdError(false);
        setPasswordText('');
        setPasswordError(false);
        navigate("/site/"+siteId)

      }


    }
  }


  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Dandara Sign In and Out Tool</h1>
        </Box>
        <Box className={styles.questionH1}>
            <h1 id="emailLoginStyle">Site ID</h1>
          </Box>
          <Box className={styles.textField}>
          <FormControl fullWidth margin="normal" className={styles.field} error={siteIdError}>
              <TextField error={siteIdError} fullWidth id="outlined-basic" label="Site ID" variant="outlined" onChange={e => handleSiteIDChange(e.target.value)}  />
              <FormHelperText>{siteIdText}</FormHelperText>
          </FormControl>
          </Box> 
          <Box className={styles.questionH1}>
              <h1>Password</h1>
              </Box>
          <Box className={styles.textField}>
          <FormControl fullWidth margin="normal" className={styles.field} error={passwordError}>
              <TextField error={passwordError} fullWidth id="outlined-basic" label="Password" type="password" variant="outlined" onChange={e => handlePasswordChange(e.target.value)}  />
              <FormHelperText>{passwordText}</FormHelperText>
          </FormControl>
          </Box> 
          <Box className={btnStyles.buttonContainer} >
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage()} 
             >Find Site</Button>
            </Box>

      <div className={styles.bottomDiv}>
          <p className={styles.bottomParagraph}>
            Were you looking for Dandara Induct? <a class="privacyLink" href="https://induct.dandara.com/" target="_blank" >Click Here</a>.
          </p>
      </div>
        
    </div>
  );
}

