import { useState, useEffect, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function DrivingQuestionsPage({userId, drivingToSite, asDriver,
     drivingToSiteNew, setDrivingToSiteNew, asDriverNew,
      setAsDriverNew, registrationNumber, setRegistrationNumber,
       transportType, setTransportType, transportSize,
        setTransportSize, fuelType, setFuelType,}) {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [response, error2,loading, axiosFetch] = useAxiosFunction();

  const [result, setResult] = useState(false);

  useLayoutEffect(() => {
    if(result){
      submitAction();
    }
    
  }, [result])


  const [transportTypeError, setTransportTypeError] = useState(false);
  const [transportTypeText, setTransportTypeText] = useState('');

  const [fuelTypeError, setFuelTypeError] = useState(false);
  const [fuelTypeText, setFuelTypeText] = useState('');

  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  const [registrationNumberText, setRegistrationNumberText] = useState('');

  const [transportSizeError, setTransportSizeError] = useState(false);
  const [transportSizeText, setTransportSizeText] = useState('');
  const [engineOptions, setEngineOptions] = useState([]);

  useEffect(() => {
    if(transportType == "car"){
      setEngineOptions([
        {value:"small", label:"small"},
        {value:"medium", label:"medium"},
        {value:"large", label:"large"}
      ]);

    }
    else if(transportType == "motorbike"){
      setEngineOptions([
        {value:"small", label:"0 to 125 cc"},
        {value:"medium", label:"126 to 500 cc"},
        {value:"large", label:"More than 500 cc"}
      ]);
      
    }
    else if(transportType == "van"){
      setEngineOptions([
        {value:"small", label:"0 to 1.305 tonnes"},
        {value:"medium", label:"1.306 to 1.740 tonnes"},
        {value:"large", label:"More than 1.750 tonnes"}
      ]);
      
    }
    else if(transportType == "other"){
      setEngineOptions([
        {value:"other", label:"Other"}
      ]);
    }
    
  }, [transportType])


  const handleTransportTypeChange = (event) => {
        setTransportType(event.target.value);
    };

    const handleFuelTypeChange = (event) => {
        setFuelType(event.target.value);
    };

  const handleRegistrationNumberChange  = (newValue) => {
      setRegistrationNumber(newValue);
  };
  const handleTransportSizeChange = (event) => {
      setTransportSize(event.target.value);
  };

  var reg_regex = /^[a-zA-Z0-9 ]{1,50}$/;

  const nextPage = () =>{

    if(transportType !== null && transportType !== "" && fuelType !== null && fuelType !== "" && registrationNumber !== null && registrationNumber !== "" && transportSize !== null && transportSize !== ""){
      //check registration regex
      setTransportTypeText('');
      setTransportTypeError(false);
      setFuelTypeText('');
      setFuelTypeError(false);
      setRegistrationNumberText('');
      setRegistrationNumberError(false);
      setTransportSizeText('');
      setTransportSizeError(false);

      console.log("reg_regex.test(registrationNumber): "+reg_regex.test(registrationNumber))

      if(!reg_regex.test(registrationNumber)){
        setRegistrationNumberText('Please enter a valid vehicle registration number containing only letters, numbers and spaces.');
        setRegistrationNumberError(true);
        return;
      }


      submit();
    }
    else{
      setTransportTypeText('');
      setTransportTypeError(false);
      setFuelTypeText('');
      setFuelTypeError(false);
      setRegistrationNumberText('');
      setRegistrationNumberError(false);
      setTransportSizeText('');
      setTransportSizeError(false);

      if(transportType === null || transportType === ""){
        setTransportTypeText('Please select a valid vehicle type.');
        setTransportTypeError(true);
      }

      if(fuelType === null || fuelType === ""){
        setFuelTypeText('Please select a valid fuel type.');
        setFuelTypeError(true);
      }

      if(registrationNumber === null || registrationNumber === ""){
        setRegistrationNumberText('Please enter a valid vehicle registration number containing only letters, numbers and spaces.');
        setRegistrationNumberError(true);
      }

      if(transportSize === null || transportSize=== ""){
        setTransportSizeText('Please select a valid engine size.');
        setTransportSizeError(true);
      }


    }
  }

  const submit = async e => {
    const bodyObj = JSON.stringify({
      "userId":userId,
      "asDriver":true,
      "registrationNumber":registrationNumber,
      "drivingToSite":true,
      "transportType":transportType,
      "transportSize":transportSize,
      "fuelType":fuelType
    });

    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/updateTransportInfo',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyObj
        }

      })
    } catch (err) {
      console.log(err);

    }
    setResult(true);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {
      //clear last user info
      setTransportType(null);
      setFuelType(null);
      setRegistrationNumber(null);
      setTransportSize(null);

      navigate("/success");
    }
  }



  return (
    <div>
        <Box className={styles.questionH1Top} >
              <h1>Vehicle Type</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={transportTypeError}>
              <InputLabel id="vehicle-type-label">Vehicle Type</InputLabel>
              <Select
                    labelId="vehicle-type-label"
                    value={transportType}
                    label="Vehicle Type"
                    onChange={handleTransportTypeChange}
                >
                    <MenuItem value={"car"}>Car</MenuItem>
                    <MenuItem value={"motorbike"}>Motorbike</MenuItem>
                    <MenuItem value={"van"}>Van</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
                  <FormHelperText>{transportTypeText}</FormHelperText>
              </FormControl>
              </Box>

            <Box className={styles.questionH1} >
              <h1>Fuel Type</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={fuelTypeError}>
              <InputLabel id="fuel-type-label">Fuel Type</InputLabel>
              <Select
                    labelId="fuel-type-label"
                    value={fuelType}
                    label="Fuel Type"
                    onChange={handleFuelTypeChange}
                >
                    <MenuItem value={"petrol"}>Petrol</MenuItem>
                    <MenuItem value={"diesel"}>Diesel</MenuItem>
                    <MenuItem value={"lpg"}>LPG</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
                  <FormHelperText>{fuelTypeText}</FormHelperText>
              </FormControl>
              </Box>

              <Box className={styles.questionH1}>
              <h1>Vehicle Registration</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={registrationNumberError}>
                 <TextField error={registrationNumberError} value={registrationNumber}  fullWidth id="outlined-basic" label="Vehicle Registration" variant="outlined" onChange={e => handleRegistrationNumberChange(e.target.value)}  />
                 <FormHelperText>{registrationNumberText}</FormHelperText>
              </FormControl>
              </Box>


              <Box className={styles.questionH1} >
              <h1>Engine Size</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={transportSizeError}>
              <InputLabel id="transport-size-label">Engine Size</InputLabel>
              <Select
                    labelId="transport-size-label"
                    value={transportSize}
                    label="Engine Size"
                    onChange={handleTransportSizeChange}

                >
                {engineOptions.map((engineOption) =>
                      <MenuItem key={engineOption.value} value={engineOption.value}>{engineOption.label}</MenuItem>
                  )}
                </Select>
                  <FormHelperText>{transportSizeText}</FormHelperText>
              </FormControl>
              </Box>


              
              <Box className={btnStyles.navbuttons} >
              <Button className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

    </div>
  );
}

