import { useState} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box} from '@mui/material';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

export default function DrivingPage({userId, drivingToSite, asDriver, isDrivingToday, setIsDrivingToday}) {

  const navigate = useNavigate();

  const nextPage = (res) =>{
    //if sign in navigate to driving question, else navigate to site page
    if(res == "no"){
        //navigate to site rules page
        setIsDrivingToday(false);
        navigate("/site-rules");
    
    }
    else if(res == "yes"){
        if(drivingToSite == true && asDriver == true){
            //navigate to site rules page
            setIsDrivingToday(true);
            navigate("/site-rules");
        }
        else{
            //Answer driving questions and then
            //navigate to site rules page
            setIsDrivingToday(true);
            navigate("/driving-questions")


        }



    }


  }



  return (
    <div>
        <>
      <Box className={styles.questionH1Top} >
        <h1>Did you drive yourself to site today?:</h1>
        </Box>
        <Box className={btnStyles.buttonContainer} >
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("no")} 
             >No</Button>
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("yes")} 
             >Yes</Button>

      </Box>
      </>
    </div>
  );
}

